/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import MyPageWrapper from '../../components/MyPageWrapper'
import SubNav from '../../components/SubNav'
import { getData } from '../../services/fetch'
import { calculateCommissionDifference, formatMoney } from '../../components/commonFnc';
import moment from 'moment'
import { OrderType, paymentCurrency, TradeStatus } from '../../components/enum'
import { AuthContext } from '../../context/authContext'
import Loading from '../../components/Loading'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { getReceiptName, setReceiptName } from '../../services/storage'

const TradeDetail = () => {
    const location = useLocation();
    const default_type = location.state?.type ?? '';
    const { id } = useParams();
    const { user } = useContext(AuthContext);
    const [type, setType] = useState(default_type);
    const [trade, setTrade] = useState(undefined);
    const [commission, setCommission] = useState(null);

    const needReceipt = useMemo(() => {
        return trade && type === OrderType.Sell && Number(trade.currency) === paymentCurrency.Yen && trade.status === TradeStatus.TRADED;
    }, [trade, type]);

    useEffect(() => {
        const getTrade = async (id) => {
            var response = await getData(`api/trades/trade-detail?trade_id=${id}`);
            if (response.ok) {
                setTrade(response.data.trade);
                setCommission(response.data.trade?.commission);
                setType(response.data.trade?.ask_order.user_id === user.id ? OrderType.Sell : OrderType.Buy)
            }
        }

        if (id) {
            getTrade(id);
        }

        return () => {
            setTrade(null);
        }
    }, [id, user])

    return (
        <MyPageWrapper pageid={type === OrderType.Buy ? "買い取引" : "売り取引"}>
            <SubNav pageid={type === OrderType.Buy ? "買い取引" : "売り取引"} items={[
                {
                    id: '買い取引',
                    name: '買い取引',
                    to: '/mypage/buy-transactions',
                },
                {
                    id: '売り取引',
                    name: '売り取引',
                    to: '/mypage/sell-transactions',
                },
            ]} />
            {
                !trade ? <Loading /> :
                    needReceipt ?
                        <DetailsContentWithReceipt type={type} commission={commission} trade={trade} /> :
                        <DetailsContent type={type} commission={commission} trade={trade} />
            }
        </MyPageWrapper>
    )
}

export default TradeDetail

const DetailsContent = ({ type, commission, trade }) => {
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <h3 className='title text-start fs-30 fs-sm-25 fw-500'>取引詳細</h3>
                    <span><small>取引ID:{trade.id}</small></span>
                    <p style={{ wordBreak: 'break-word' }}><small>ブロックチェーントランザクション：{type === OrderType.Buy ? (trade.token_receive_transaction_id || '') : (trade.token_send_transaction_id || '')}</small></p>

                    <TradeStatusMessage type={type} status={trade.status} />
                    <div className='row px-lg-5 pt-3'>
                        <div className='col-6 col-lg-9 text-end'>
                            {type === OrderType.Sell ? "販売金額" : "購入金額"}

                        </div>
                        <div className='col-6 col-lg-3 text-end'>
                            {formatMoney(trade.amount, trade.currency)}
                        </div>
                    </div>
                    {type === OrderType.Sell ?
                        <div className='row px-lg-5 pt-3'>
                            <div className='col-6 col-lg-9 text-end'>
                                販売手数料
                            </div>
                            <div className='col-6 col-lg-3 text-end'>
                                {formatMoney(commission, trade.currency)}
                            </div>
                        </div>
                        : <>{type}</>
                    }
                    <div className="divider my-3"></div>
                    <div className='row px-lg-5 pt-3'>
                        <div className='col-6 col-lg-9 text-end'>
                            入金金額
                        </div>
                        <div className='col-6 col-lg-3 text-end'>
                            <h4>{formatMoney(type === OrderType.Sell ? trade.transfer_amount : trade.amount, trade.currency)}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-lg-4'>
                    <img src={trade.token ? trade.token['image'] : ''} className='w-100' />
                </div>
                <div className='col-12 col-lg-8 pt-3'>
                    <p>名前：{trade.token ? trade.token['name'] : ''}</p>
                    <p>落札日：{moment(trade.matched_at).format("yyyy/MM/D")}</p>
                </div>
            </div>
        </>
    )
}

const DetailsContentWithReceipt = ({ type, commission, trade }) => {
    const [name, setName] = useState(getReceiptName());
    const isSP = useMediaQuery({ maxWidth: 576 });
    const handleNameChange = (e) => {
        setName(e.target.value);
    }
    useEffect(() => {
        if (name !== getReceiptName()) {
            setReceiptName(name);
        }
    }, [name])
    return (
        <>
            <h3 className='title text-center text-sm-start fs-25 fs-sm-30 fw-500'>取引詳細</h3>
            <div className='row'>
                <div className='col-12 col-md-7 order-2 order-md-1 fs-15'>

                    {!isSP ?
                        <>
                            <span><small>取引ID:{trade.id}</small></span>
                            <p><small>ブロックチェーントランザクション：{type === OrderType.Buy ? (trade.token_receive_transaction_id || '') : (trade.token_send_transaction_id || '')}</small></p>
                            <TradeStatusMessage type={type} status={trade.status} />
                        </> :
                        <>
                            <div className='row px-lg-5 pt-3'>
                                <div className={`col-6 col-lg-9 text-start ${isSP ? 'fw-600' : ''}`}>
                                    取引ID
                                </div>
                                <div className='col-6 col-lg-3 text-end fs-15 fs-sm-20'>
                                    {trade.id}
                                </div>
                            </div>
                            <div className='row px-lg-5 pt-3'>
                                <div className={`col-6 col-lg-9 text-start ${isSP ? 'fw-600' : ''}`}>
                                    ブロックチェーン TX
                                </div>
                                <div className='col-6 col-lg-3 text-end fs-15 fs-sm-20'>
                                    <div className='text-overflow'>
                                        {type === OrderType.Buy ? (trade.token_receive_transaction_id || '') : (trade.token_send_transaction_id || '')}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='row px-lg-5 pt-3'>
                        <div className={`col-6 col-lg-9 text-start ${isSP ? 'fw-600' : ''}`}>
                            {type === OrderType.Sell ? "販売金額" : "購入金額"}

                        </div>
                        <div className='col-6 col-lg-3 text-end fs-15 fs-sm-20'>
                            {formatMoney(trade.amount, trade.currency)}
                        </div>
                    </div>
                    {type === OrderType.Sell ?
                        <div className='row px-lg-5 pt-3'>
                            <div className={`col-6 col-lg-9 text-start ${isSP ? 'fw-600' : ''}`}>
                                利用手数料
                            </div>
                            <div className='col-6 col-lg-3 text-end fs-15 fs-sm-20'>
                                {formatMoney(commission, trade.currency)}
                            </div>
                        </div>
                        : <>{type}</>
                    }
                    <div className='row px-lg-5 fw-600' style={{ marginTop: 33 }}>
                        <div className='col-6 col-lg-9 text-start fs-20'>
                            受領金額
                        </div>
                        <div className='col-6 col-lg-3 text-end fs-25'>
                            <h4>{formatMoney(type === OrderType.Sell ? trade.transfer_amount : trade.amount, trade.currency)}</h4>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-5 order-1 order-md-2 receipt_image'>
                    <div className='row text-center'>
                        <div className='col-12'>
                            <img src={trade.token ? trade.token['image'] : ''} />
                        </div>
                        <div className='col-12 pt-3'>
                            <p className={`fs-15 fs-sm-20 ${isSP ? 'fw-bold' : ''} mb-1`}>名前：{trade.token ? trade.token['name'] : ''}</p>
                            <p className='fs-20 fw-bold'>落札日：{moment(trade.matched_at).format("yyyy/MM/D")}</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 order-3'>
                    <div className='m-t-40 m-b-13 m-t-md-38 m-b-md-9 text-center text-sm-start fs-15'>
                        ※下記領収書はOCtillion利用手数料（10%）のものです。宛名はご自身で入力可能です。
                    </div>
                    <div className='receipt'>
                        <div className='row'>
                            <div className='col-12 col-md-4 order-3 order-md-1 name'>
                                <div className='d-flex flex-row align-items-center'>
                                    <input type='text' className='name_box text-center' value={name} onChange={handleNameChange} />
                                    <span>様</span>
                                </div>
                            </div>
                            <div className='col-12 col-md-4 text-center order-2 title'>
                                領収書
                            </div>
                            <div className='col-12 col-md-4 order-1 order-md-3 date'>
                                <div className='date-wrapper'>
                                    <div className='d-flex flex-row justify-content-between mb-2'>
                                        <span>取引年月日:</span>
                                        <span>{moment(trade.traded_at).format('YYYY年MM月DD日')}</span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <span>トークンID:</span>
                                        <span>{trade.token.token_id}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 description order-4'>
                                <div className='row flex-center align-items-end'>
                                    <div className='col-12 col-md-2 col-lg-1'></div>
                                    <div className='col-12 col-md-4 col-lg-5'>
                                        <div className='d-flex flex-row justify-content-center align-items-end'>
                                            <div className='d-flex align-items-end'>
                                                <span className='amount'>{formatMoney(trade.commission)}円</span>
                                                <span className='ms-2'>（税込）</span>
                                            </div>
                                            <span className='ms-4'>{formatMoney(trade.amount / trade.commission)}％対象</span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4 col-lg-5'>
                                        <div className='d-flex flex-row justify-content-center align-items-end'>
                                            <span className='ms-2'>うち{formatMoney(trade.amount / trade.commission)}％対象消費税</span>
                                            <span className='ms-4'>{formatMoney(calculateCommissionDifference(commission))}円</span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-2 col-lg-1'></div>
                                </div>
                                <div className='row sub_description'>
                                    <div className='col-12 text-center'>
                                        NFTセカンダリーマーケットプレイス Octillion 利用手数料として
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 footer order-5 text-center text-sm-start'>
                                領収者：ループコネクト株式会社<br />
                                登録番号：T9011101099350
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const TradeStatusMessage = ({ type, status }) => {
    return (
        <div className='fs-20'>
            {status === TradeStatus.ERROR ?
                <div className="alert alert-default" role="alert">
                    <h4 className='text-danger fw-400'>エラー</h4>
                    <span>取引中にエラーが発生したため、キャンセルされました。</span>
                </div>
                : <></>
            }
            {status === TradeStatus.TOKEN_RECEIVED ?
                <div className="alert alert-default" role="alert">
                    <h4 className='text-danger fw-400'>支払い待ち</h4>
                    <span>{type === OrderType.Sell ? '買い手の決済待ちです。' : ''}</span>
                </div>
                : <></>
            }
            {status === TradeStatus.TRADED ?
                <div className="alert alert-default" role="alert">
                    <h4 className='text-primary fw-400'>取引完了</h4>
                    <span>取引が正常に完了しました。</span>
                </div>
                : <></>
            }
            {status !== TradeStatus.TOKEN_RECEIVED && status !== TradeStatus.ERROR && status !== TradeStatus.TRADED ?
                <div className="alert alert-default" role="alert">
                    <h4 className='text-danger fw-400'>取引中</h4>
                    <span>取引実行中です。しばらくお待ち下さい。</span>
                </div>
                : <></>
            }
        </div>
    )
}