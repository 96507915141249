const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const REACT_APP_MP_WALLET_ADDRESS = process.env.REACT_APP_MP_WALLET_ADDRESS;
const REACT_APP_ARCANA_CONTRACT_ADDRESS = process.env.REACT_APP_ARCANA_CONTRACT_ADDRESS;
const REACT_APP_EGG_CONTRACT_ADDRESS = process.env.REACT_APP_EGG_CONTRACT_ADDRESS;
const REACT_APP_PERSONA_CONTRACT_ADDRESS = process.env.REACT_APP_PERSONA_CONTRACT_ADDRESS;
const REACT_APP_NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
const REACT_APP_ANIMA_CONTRACT_ADDRESS = process.env.REACT_APP_ANIMA_CONTRACT_ADDRESS
const REACT_APP_BLOCKCHAIN_API_URL = process.env.REACT_APP_BLOCKCHAIN_API_URL;
const REACT_APP_WALLET_CLIENT_ID = process.env.REACT_APP_WALLET_CLIENT_ID;
const REACT_APP_RPC_TARGET = process.env.REACT_APP_RPC_TARGET;
const REACT_APP_BLOCK_EXPLORER = process.env.REACT_APP_BLOCK_EXPLORER;
const REACT_APP_CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
const REACT_APP_INCUBATOR_CONTRACT_ADDRESS = process.env.REACT_APP_INCUBATOR_CONTRACT_ADDRESS;
const REACT_APP_WEB3AUTH_NETWORK = process.env.REACT_APP_WEB3AUTH_NETWORK;
const REACT_APP_WEB3AUTH_VERIFIER_NAME = process.env.REACT_APP_WEB3AUTH_VERIFIER_NAME;
const REACT_APP_PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
const REACT_APP_PORTAL_MALL_URL = process.env.REACT_APP_PORTAL_MALL_URL;
const REACT_APP_LOGIN_CALLBACK_WHITELIST = process.env.REACT_APP_LOGIN_CALLBACK_WHITELIST;
const REACT_APP_R_SERVER = process.env.REACT_APP_R_SERVER

export {
    REACT_APP_API_URL,
    REACT_APP_MP_WALLET_ADDRESS,
    REACT_APP_ARCANA_CONTRACT_ADDRESS,
    REACT_APP_EGG_CONTRACT_ADDRESS,
    REACT_APP_PERSONA_CONTRACT_ADDRESS,
    REACT_APP_NULL_ADDRESS,
    REACT_APP_ANIMA_CONTRACT_ADDRESS,
    REACT_APP_BLOCKCHAIN_API_URL,
    REACT_APP_WALLET_CLIENT_ID,
    REACT_APP_RPC_TARGET,
    REACT_APP_BLOCK_EXPLORER,
    REACT_APP_CHAIN_ID,
    REACT_APP_INCUBATOR_CONTRACT_ADDRESS,
    REACT_APP_WEB3AUTH_VERIFIER_NAME,
    REACT_APP_WEB3AUTH_NETWORK,
    REACT_APP_PORTAL_URL,
    REACT_APP_PORTAL_MALL_URL,
    REACT_APP_LOGIN_CALLBACK_WHITELIST,
    REACT_APP_R_SERVER
}
