import { getData, postData } from "../services/fetch";
import Swal from "./Swal";
import { currencyUnit } from '../components/enum'

const BigNumber = require('bignumber.js');

const { BN } = require("web3-utils");

var formatter = (decimalPlaces) => {
  const maxDecimalPlaces = 18
  const fractionDigits = decimalPlaces > maxDecimalPlaces ? maxDecimalPlaces : decimalPlaces

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "JPY",
    maximumFractionDigits: fractionDigits,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
};
export const toBigNumber = (etherValue, decimalPlaces) => {
  const value = new BigNumber(etherValue).times(new BigNumber(10).pow(decimalPlaces));
  return value.toFixed(0);
};

export const getDecimalPlaces = amount => amount?.toString().includes('.') ? amount.toString().split('.')[1].length : 0;

export const formatMoney = (amount, currency) => {
  const unit = currencyUnit(currency)
  return formatter(getDecimalPlaces(amount)).format(amount).replace(/^(\D+)/, unit + ' ')
}

export const formatNumber = amount => formatter(getDecimalPlaces(amount)).format(amount).replace(/^(\D+)/, '')

export const getTokenDetail = async (token_type_id, id) => {
  var response = await getData(
    `api/tokens/get-token-detail?token_type_id=${token_type_id}&token_id=${id}`
  );
  if (response.ok) {
    return response.data.token;
  }
  return {};
};

export const handleBookmark = async (
  token_id,
  token_type_id,
  func = () => {}
) => {
  var response = await postData(`api/tokens/click-bookmark`, {
    token_id,
    token_type_id,
  });
  if (response.ok) {
    if (func) func();
  }
};

const gradientDuration = 500;

export const playGradientColor = (e) => {
  var x = e.target;
  x.classList.add("hover");
  setTimeout(() => x.classList.remove("hover"), gradientDuration);
};

export const addGradientColor = (e) => {
  removeAllGradientColor();
  var x = e.target;
  x.classList.add("hover");
};

export const addGradientColorById = (id) => {
  removeAllGradientColor();
  var x = document.getElementById(id);
  x.classList.add("hover");
};

export const addGradientColorByClass = (classes) => {
  removeAllGradientColor();
  var x = document.getElementsByClassName(classes);
  Array.from(x).forEach((el) => {
    el.classList.add("hover");
  });
};

export const removeAllGradientColor = (e) => {
  var classes = document.getElementsByClassName("gradient-text-hover");
  Array.from(classes).forEach((el) => {
    el.classList.remove("hover");
  });
};

export const removeGradientColor = (e) => {
  var x = e.target;
  setTimeout(() => x.classList.remove("hover"), gradientDuration);
};

export const scrollWidthOffset = (el) => {
  // const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  // const yOffset = -70;
  // console.log('scrollWidthOffset', el, yCoordinate, yOffset);
  // document.getElementById('subbody').scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  el.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const shorternAddress = (address) =>
  address
    ? `${address.substring(0, 6)}...${address.substring(
        address.length - 2,
        address.length
      )}`
    : "";

export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  Swal.fire({
    icon: "success",
    title: "クリップボードにコピーしました",
  });
};

export const getUniqueId = (prefix) => {
  const random = Math.floor(Math.random() * 10000000);
  const date = new Date().getTime();

  return `${prefix}_${random}_${date}`;
};

export const formatPhoneNumberForDisplay = (phoneNumber, dialCode) => {
  var raw = dialCode ? phoneNumber.slice(dialCode.length) : phoneNumber;
  return raw;
};

export const cutNumber = (number, digitsAfterDot) => {
  const str = `${number}`;

  if (Number.isInteger(Number(str))) {
      return parseInt(str).toFixed(digitsAfterDot)
  } else {
      var res = str.slice(0, str.indexOf('.') + digitsAfterDot + 1);
      if (digitsAfterDot) {
          return res.padEnd(str.indexOf('.') + digitsAfterDot + 1, "0");
      } else {
          return res.split('.')[0];
      }
  }
}

export const calculateBN = (balance, decimals, decimal_places = 0, showFullBalance = false) => {
    balance = window.BigInt(balance ?? 0).toString() || 0;
    balance = balance || 0;
    decimals = decimals || 0;
    const decimalsBN = new BN(10).pow(new BN(decimals));
    const amountBN = new BN(balance.toString()).divmod(decimalsBN);
    const base = amountBN.div + "." + amountBN.mod.toString(10, decimals);
    return showFullBalance ? base : cutNumber(base, decimal_places);
}

export const calculateCommissionDifference = (commissionFee) => {
  // Calculate the base amount by dividing the commission fee by 1.1, then rounding up
  const baseAmount = Math.ceil(commissionFee / 1.1);
  
  // Calculate the adjusted commission by multiplying the base amount by 1.1, then rounding down
  const adjustedCommission = Math.floor(baseAmount * 1.1);
  
  // Return the difference between adjustedCommission and baseAmount
  return adjustedCommission - baseAmount;
}